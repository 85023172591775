@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');

body * {
    font-family: 'Source Sans Pro', sans-serif;
}

/* .menu-item, .menu-item * {
    font-weight: bold !important;
} */

.read-only {
    cursor: not-allowed;
}

.field-container {
    margin-top: 1rem;
}

.summernote h1 {
    font-size: xx-large !important;
    font-weight: bolder !important;
}

.summernote h2 {
    font-size: x-large !important;
    font-weight: bolder !important;
}

.summernote h3 {
    font-size: larger !important;
    font-weight: bolder !important;
}

.summernote h4 {
    font-size: large !important;
    font-weight: bolder !important;
}

.summernote h5 {
    font-size: medium !important;
    font-weight: bolder !important;
}

.summernote h6 {
    font-size: medium !important;
    font-weight: bold !important;
}